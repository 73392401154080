import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICatalogsCategory, IProductLightModel } from "@app/products/products.models";
import { ICategoryModel } from "@app/shared/shared.models";

@Injectable({
	providedIn: "root",
})
export class CategoryService {

	constructor(private http: HttpClient) { }

	getCategory(id: number) {
		return this.http.get<ICategoryModel>("api/categories/getCategory", { params: { id: id.toString() } });
	}

	getCategories(ids: number[]) {
		return this.http.get<ICatalogsCategory[]>("api/categories/GetCategories", { params: { ids: ids.map(String) } });
	}

	getCategoryPartsForCartType(id: number, cartType: string) {
		return this.http.get<IProductLightModel[]>("api/categories/getCategoryPartsForCartType", { params: { id: id.toString(), cartType } });
	}

	getCategoryUrl(category: ICatalogsCategory): string {
		if (category.categoryName) {
			return `/products/${category.categoryId}`;
		}
	}
}
