import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "@app/services/auth.service";
import { startsWithIgnoreCase } from "@app/shared/helpers";
import { IAppState } from "@app/store";
import { isAuthenticated, LogoutAttempt } from "@app/store/auth";
import { ExternalRedirect } from "@app/store/router";
import { activeHasAccountRole, activeHasRole, currentHasAccountRole, currentHasCompletedMigration, currentHasMigrationScheduled, currentHasRole, currentIsMigrating, getActiveUserWithAccount } from "@app/store/user";
import { environment } from "@env/environment";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

/**
 * Prevent unauthorized activating and loading of routes
 * @class AuthenticatedGuard
 */
@Injectable()
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
	constructor(private store: Store<IAppState>, private authService: AuthService, private router: Router) { }

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.isAuthenticated(route, state);
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.isAuthenticated(route, state);
	}

	private isAuthenticated(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// get observable
		const checkForActiveUser = !!route.data.checkForActiveUser;

		const requiredRoleFlag = route.data.requiredRole;
		const requiresRole = !!requiredRoleFlag;

		const requiredAccountRoleFlag = route.data.requiredAccountRole;
		const requiresAccountRole = !!requiredAccountRoleFlag;

		const userHasRole = checkForActiveUser ? activeHasRole(requiredRoleFlag) : currentHasRole(requiredRoleFlag);
		const accountHasRole = checkForActiveUser ? activeHasAccountRole(requiredAccountRoleFlag) : currentHasAccountRole(requiredAccountRoleFlag);

		return combineLatest([
			this.store.pipe(select(requiresRole ? userHasRole : isAuthenticated)),
			this.store.pipe(select(requiresAccountRole ? accountHasRole : isAuthenticated)),
			this.store.pipe(select(currentIsMigrating())),
			this.store.pipe(select(currentHasCompletedMigration())),
			this.store.pipe(select(currentHasMigrationScheduled())),
			this.store.pipe(select(getActiveUserWithAccount))
		]).pipe(
			map(([user, account, userIsMigrating, completedMigration, migrationScheduled, userAccount]) => ({ user, account, userIsMigrating, completedMigration, migrationScheduled, userAccount })),
			map(auth => {
				const jwt = this.authService.getActiveJwt();
				const migrationPath = auth.completedMigration ? "/new/dealer-migration-complete" : "/new/dealer-migration";
				if (auth.user && !jwt) {
					this.store.dispatch(new LogoutAttempt({ loginRedirect: state.url }));
				} else if (auth.userIsMigrating && location.pathname !== migrationPath) {
					return this.router.createUrlTree([migrationPath]);
				} else if (!auth.userIsMigrating && location.pathname.includes("new/dealer-migration")) {
					return this.router.createUrlTree(["/404"], { queryParams: { url: state.url } });
				} else if (!auth.migrationScheduled && location.pathname.includes("new/dealer-pre-migration")) {
					return this.router.createUrlTree(["/404"], { queryParams: { url: state.url } });
				} else if (auth.userAccount && jwt) {
					const isReadOnly = auth.userAccount.account.isReadOnly;
					if (isReadOnly) {
						if (["/", "/404", "/rma", "/account/orders", "/account/balance"].includes(location.pathname)) {
							return auth.user && auth.account;
						}
						else {
							return this.router.createUrlTree(["/"], { queryParams: { url: state.url } });
						}
					}
				} else if ((requiresRole && !auth.user) || (requiresAccountRole && !auth.account)) {
					return this.router.createUrlTree(["/401"], { queryParams: { url: state.url } });
				} else if (!auth.user && !this.authService.getIsExternal()) {
					return this.router.createUrlTree(["/login"], { queryParams: { redirect: state.url } });
				} else if (this.authService.getIsExternal()) {
					if (startsWithIgnoreCase(state.url, "/saml")) {
						this.store.dispatch(new ExternalRedirect(environment.secondaryPortalUrl + state.url));
					} else {
						this.authService.clearIsExternal();
						location.reload();
					}
				}

				return auth.user && auth.account;
			}),
		);
	}
}
